/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


.split-pane-visible>.split-pane-side {
    min-width: 230px !important;
    max-width: 230px !important;
}

// .main-menu {
//     margin-left: 14px;
// }

// .main-menu ion-button {
//     text-transform: uppercase;
// }

// .right-menu {
//     margin-right: 14px;
// }

.right-menu ion-img {
    width: 24px;
}

// .headline {
//     position: relative;
//     top: 64px;
//     background: var(--ion-color-secondary);
//     color: var(--ion-color-light);
//     padding: 2px 25px;
//     left: -16px;
//     width: 100vw;
//     font-size: 14px;
// }

// .headline.show-background  {
//     top: 47px;
// }

ion-header,
app-header-front {
    transition: .4s;
}

ion-card-content {
    line-height: 1.2;
    padding-bottom: 8px;
}

.gps-tabs {
    display: flex;
    justify-content: flex-start;
    position: sticky;
    top: 0;
    z-index: 5;
    background: #fff;
    border-bottom: 1px solid #ccc;
    padding: 10px 20px;
    overflow: auto;
}

.gps-tabs ion-button {
  margin: 0 10px;
}

div.error-message {
    color: #cc0033;
    display: inline-block;
    font-size: 12px;
    line-height: 15px;
    margin: 5px 0 0;
    width: 100%;
  }

div.input-message {
  display: inline-block;
  font-size: 12px;
  line-height: 15px;
  margin: 5px 0 0;
  width: 100%;
}
form label.required:after
{
    color: red;
    content: " *";
}

.md .gps-logo-header {
    max-height: 34px;
    position: relative;
    margin: 0 0 -5px 6px;
    top: 0px;
}

.ios .gps-logo-header {
    max-height: 30px;
    position: absolute;
    left: 48px;
    top: 7px;
}

ion-title {
    max-width: 80%;
}

.ios ion-card-title {
    font-size: 20px;
    max-width: 100%;
}

.alert-wrapper {
    --width: 450px;
    --max-width: 450px;
}

li-ionic4-datepicker {
    width: calc(100% - 106px);
}

li-ionic4-datepicker input {
    border-bottom: none !important;
}

footer {
    font-size: .75rem;
    padding: 0 36px!important;
    margin-top: -24px;
}

@media screen and (min-width:992px) {
    .md .gps-logo-header {
        margin: 0 0 -5px 20px;
    }
    ion-menu-button {
        display: none;
    }
}

@media screen and (max-width:480px) {
    .main-menu ion-button {
        font-size: 13px;
    }
    .headline {
        padding: 6px 25px;
        font-size: 12px;
    }
}

@media screen and (max-width:360px) {
    .main-menu ion-button {
        font-size: 12px;
    }
    .right-menu ion-img {
        width: 20px;
    }
}